.iframe-container {
    position: relative;
    width: 100%;
    height: 100vh;
    padding-top: 56.25%;
    /* 16:9 aspect ratio */
}

.iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
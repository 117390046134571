@keyframes blink {
    0% {
        box-shadow: unset;
    }

    50% {
        box-shadow: 0 0 30px #f0850a, 0 0 10px #f0850a;
    }

    100% {
        box-shadow: unset;
    }

}
:root {
  --body-bg: white;
  --body-color: black;
  --left-bg: linear-gradient(45deg, #060a0f, #253445, #060a0f);
  --left-color: white;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--body-bg) !important;
  color: var(--body-color) !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}


@keyframes blurAnimation {
  0% {
    filter: blur(0px);
  }

  60% {
    filter: blur(0px);
  }

  70% {
    filter: blur(0px) hue-rotate(160deg);
  }

  90% {
    filter: blur(3px) hue-rotate(160deg);
  }

  100% {
    filter: blur(0px);
  }

}


@keyframes sunSpinAnimation {
  0% {
    transform: rotate(0deg);
  }

  60% {
    transform: rotate(0deg);
  }

  70% {
    transform: rotate(0deg);

  }

  90% {
    transform: rotate(720deg);

  }

  91% {
    transform: rotate(0deg);

  }

  100% {
    transform: rotate(0deg);
  }

}

@keyframes slide-page {
  0% {
    clip-path: inset(0 100% 0 0);
    -webkit-clip-path: inset(0 100% 0 0);
  }
  100% {
    clip-path: inset(0 0 0 0);
    -webkit-clip-path: inset(0 0 0 0);
  }
}

